/**
 * Created by gschulz on 06.07.15.
 */
var AircraftEquipment = (function () {

    var _private = {};

    _private.form = $();

    _private.instance = this;

    _private.equipmentData = null;

    /**
     *
     * @param {*} form
     */
    this.init = function (form) {
        _private.form = $(form);
        var formTable = _private.form.find('fieldset.equipment-list.form-table');
        _private.form.data('AircraftEquipment', this);
        new AircraftFormTable().bindFormTable(formTable);
        _private.bindEvents();
        return this;
    };


    this.getEquipmentData = function (force) {
        if (force || !_private.equipmentData) {
            _private.calculate();
        }
        return _private.equipmentData;
    };

    _private.calculate = function () {
        var equipmentData = {
            items: {},
            total: {
                equipment: 0,
                cargo:     0,
                seat:      0,
                weight:    0,
                moment:    {lon: 0, lat: 0}
            }
        };
        var k             = 0;
        _private.form.find('fieldset.cog-list').not('.aircraft-line').each(function (t, table) {
            table         = $(table);
            var firstItem = table.find('div.form-table-line').find('input[type=text]').first();
            if (!firstItem.length) {
                return;
            }
            var type = firstItem.attr('name').split('[')[0];
            table.find('div.form-table-line').each(function (i, line) {
                line          = $(line);
                var installed = line.find('input[type=checkbox][name*=installed]').is(':checked');
                var weighted  = line.find('input[type=checkbox][name*=weighted]').is(':checked');
                var weight    = line.find('input[name*="[weight]"]').uVal();
                if (line.hasClass('inactive') || line.hasClass('aircraft')) {
                    return;
                }
                if (!installed) {
                    weight = weighted ? weight * -1 : 0;
                } else {
                    weight = weighted ? 0 : weight;
                }
                equipmentData.items[k]           = {};
                equipmentData.items[k]['weight'] = weight;
                equipmentData.total[type] += weight;
                equipmentData.total.weight += equipmentData.items[k]['weight'];
                equipmentData.items[k]['arm']    = {};
                $.each(['lon', 'lat'], function (i, type) {
                    equipmentData.items[k]['arm'][type] = line.find('input[name*="[arm][' + type + ']"]').uVal();
                    equipmentData.total.moment[type] +=
                        (equipmentData.items[k]['arm'][type] * equipmentData.items[k]['weight']);
                });
                k++;
            });
        });
        _private.equipmentData = equipmentData;
        return equipmentData;
    };


    /**
     *
     * @param {*} line
     */
    _private.handleEquipmentLine = function (line) {
        var weightElement = line.find('input[name*="[weight]"]');
        var installed     = line.find('input[type=checkbox][name*=installed]').is(':checked');
        var weighted      = line.find('input[type=checkbox][name*=weighted]').is(':checked');
        if (installed) {
            line.addClass('installed');
        } else {
            line.removeClass('installed');
        }
        if (!weighted) {
            return;
        }
        var displayValue = !installed ? weightElement.pVal() * -1 : 0;
        if (!_.isUndefined(weightElement.data('format'))) {
            displayValue = $.sprintf(weightElement.data('format'), displayValue);
        } else if (!_.isUndefined(weightElement.attr('precision'))) {
            displayValue = parseFloat(displayValue.toFixed(parseInt(weightElement.attr('precision')))).toString();
        } else {
            displayValue = Math.round(displayValue);
        }
        weightElement.val(displayValue);
    };


    _private.bindEvents = function () {
        var eventHelper    = new EventHelper();
        var formTableLines = _private.form.find('div.form-table-line');
        _private.form.find('fieldset.cog-list')
                .unbind('formTableElement').on('formTableElement', function (event) {
            _private.instance.init($(event.target).parents('form'));
        });
        formTableLines.find('input[name*=installed]')
                      .unbind('click.massAndBalance').on('click.massAndBalance',
            function (event) {
                var line = $(event.target).parents('div.form-table-line');
                var form = line.parents('form');
                _private.handleEquipmentLine(line);
                _private.calculate();
                form.trigger('weightChange');
            }
        );
        formTableLines.find('input[name*="[arm]"],input[name*="[weight]"]')
                      .unbind('keyup.calculateLine').on('keyup.calculateLine', function (event) {
                eventHelper.delayCall(
                    function () {
                        _private.calculate();
                        _private.form.trigger('weightChange');

                    },
                    'calculateEquipmentLine', [event]);
            }
        );
    };


});